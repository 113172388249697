import { ENV_PREFIX } from './constants';

export type EnvVariable = 'ENVIRONMENT' | 'TARGET_PLATFORM' | 'AUTH_API_URL' | 'CLIENT_PORTAL_API_URL' | 'ISP_API_URL';

function getEnvVariable(name: EnvVariable): string {
  let envVariable = '';
  if (import.meta.env.PROD) {
    envVariable = (window.__ENV && window.__ENV[name]) ?? '';
  } else {
    envVariable = import.meta.env[`${ENV_PREFIX}${name}`] ?? '';
  }
  return envVariable;
}

export default getEnvVariable;
