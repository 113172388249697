import { ispApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { SchemeResponse, SchemeConfigurationResponse } from 'src/types';

export const getSchemeListByCompanyId = (companyId: string, config: AxiosRequestConfig = {}) =>
  ispApi.get<SchemeResponse[]>(`/isp-internal/api/v1/schemes/assignedToCompany/${companyId}`, config);

export const getCompanyConfigurationSchemeListByCompanyId = (companyId: string, config: AxiosRequestConfig = {}) =>
  ispApi.get<SchemeConfigurationResponse[]>(
    `/isp-internal/api/v1/schemes/companies/${companyId}/configurations`,
    config,
  );

export const enableSchemePDPPublish = (schemeId: string) =>
  ispApi.patch<void>(`/isp-internal/api/v1/schemes/schemes/${schemeId}/pdp`);

export const disableSchemePDPPublish = (schemeId: string) =>
  ispApi.delete<void>(`/isp-internal/api/v1/schemes/schemes/${schemeId}/pdp`);
