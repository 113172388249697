import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export const membershipSliceSelector = (state: RootState) => state.membership;

export const selectedMembershipSelector = createSelector(membershipSliceSelector, (state) => state.selectedMembership);
export const selectedMembershipIdSelector = createSelector(selectedMembershipSelector, (state): string =>
  state && state.membershipId ? state.membershipId : '',
);
export const selectedMembershipIdNameSelector = createSelector(selectedMembershipSelector, (state): string =>
  state ? state.membershipSrcId || state.membershipId || '' : '',
);

export const selectedPensionRefSelector = createSelector(selectedMembershipSelector, (state): string =>
  state && state.pensionRef ? state.pensionRef : '',
);

export const fullNameMembershipSelector = createSelector(selectedMembershipSelector, (state) =>
  state ? state.fullName : '',
);

export const selectedMembershipIsViewSelector = createSelector(membershipSliceSelector, (state) => state.isView);

export const isLoadingMembershipSelector = createSelector(membershipSliceSelector, (state) => state.isLoading);

export const membershipServerErrorsSelector = createSelector(membershipSliceSelector, (state) => state.serverErrors);
