import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch as useDispatchOriginal, useSelector } from 'react-redux';

import { resetStore } from './slices/storeSlice';
import authReducer from './slices/authSlice';
import routerReducer from './slices/routerSlice';
import companyReducer from './slices/companySlice';
import membershipReducer from './slices/membershipSlice';
import productReducer from './slices/productSlice';
import schemeReducer from './slices/schemeSlice';
import tenantReducer from './slices/tenantSlice';
import breadcrumbsReducer from './slices/breadcrumbsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  router: routerReducer,
  company: companyReducer,
  membership: membershipReducer,
  product: productReducer,
  scheme: schemeReducer,
  tenant: tenantReducer,
  breadcrumbs: breadcrumbsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof rootReducer>;
export type StoreDispatch = typeof store.dispatch;

export const useDispatch = () => useDispatchOriginal<StoreDispatch>();
export { useSelector, resetStore };

export default store;
