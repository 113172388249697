import { useEffect, useMemo, useState } from 'react';
import { useLocation, matchPath, PathMatch, generatePath } from 'react-router-dom';
import { RoutePath as rp } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import { setBreadcrumbsNames } from 'src/store/slices/breadcrumbsSlice';
import { breadcrumbsNamesMapSelector } from 'src/store/selectors/breadcrumbsSelector';

import { Breadcrumb, Breadcrumbs } from '@itm/shared-frontend/lib/components';

type BreadcrumbConfig = {
  name: string | null | undefined;
  parentPath: string | null;
};

type ExtendedBreadcrumbsConfig = BreadcrumbConfig & Pick<PathMatch<string>, 'pathname'>;

type BreadcrumbConfigMap = {
  [key: string]: BreadcrumbConfig;
};

type UseSetBreadcrumbsNamesProps = Readonly<{
  routePath: string;
  value: string | null | undefined;
  isClearOnUnmount?: boolean;
}>;

export const useSetBreadcrumbsNames = ({ routePath, value, isClearOnUnmount = true }: UseSetBreadcrumbsNamesProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!value) return;
    dispatch(setBreadcrumbsNames({ [routePath]: value }));
  }, [routePath, value, dispatch]);

  useEffect(
    () => () => {
      if (!isClearOnUnmount) return;
      dispatch(setBreadcrumbsNames({ [routePath]: null }));
    },
    [routePath, isClearOnUnmount, dispatch],
  );
};

const getBreadcrumbs = (pathToMatch: string, breadcrumbsConfig: BreadcrumbConfigMap): Breadcrumb[] => {
  const matchedList = Object.keys(breadcrumbsConfig).reduce<PathMatch<string>[]>((acc, route) => {
    const matched = matchPath(route, pathToMatch);
    return matched ? [...acc, matched] : acc;
  }, []);

  if (!matchedList.length) return [];

  const pathMatch =
    // try to find the full match
    matchedList.find((m) => !m.pattern.path.endsWith('/*')) ||
    // if not found, find the longest wildcard match
    matchedList.toSorted((a, b) => b.pattern.path.length - a.pattern.path.length)[0];

  const routeParams = pathMatch.params;

  let revertedBreadcrumbsList: Breadcrumb[] = [];
  let extendedConfig: ExtendedBreadcrumbsConfig | null = {
    pathname: pathMatch.pathname,
    ...breadcrumbsConfig[pathMatch.pattern.path],
  };

  while (extendedConfig) {
    const { name, pathname, parentPath }: ExtendedBreadcrumbsConfig = extendedConfig;

    if (name) {
      revertedBreadcrumbsList.push({ name, to: pathname });
    } else {
      revertedBreadcrumbsList = [];
    }

    extendedConfig = parentPath
      ? { ...breadcrumbsConfig[parentPath], pathname: generatePath(parentPath, routeParams) }
      : null;
  }

  return revertedBreadcrumbsList.reverse();
};

function BreadcrumbsTrail() {
  const { pathname } = useLocation();
  const bcNamesMap = useSelector(breadcrumbsNamesMapSelector);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const isHidden = useMemo(
    () =>
      Boolean(
        matchPath(rp.root, pathname) ||
          matchPath(rp.loginRedirect, pathname) ||
          matchPath(rp.permissionDenied, pathname),
      ),
    [pathname],
  );

  const config: BreadcrumbConfigMap = useMemo(
    () => ({
      [rp.root]: { name: 'Pension Fusion', parentPath: null },

      // CONFIGURATION
      [rp.configurationRoot]: { name: 'Configuration', parentPath: rp.root },

      // Configuration / General Settings
      [rp.configurationGeneralSettingsList]: { name: 'General Settings', parentPath: rp.configurationRoot },

      // Configuration / General Settings / View Company
      [rp.configurationGeneralSettingsViewRoot]: {
        name: bcNamesMap[rp.configurationGeneralSettingsViewRoot],
        parentPath: rp.configurationGeneralSettingsList,
      },
      // Configuration / General Settings / View Company / PDP Connection
      [rp.configurationGeneralSettingsViewPDPConnectionRoot]: {
        name: 'PDP connection',
        parentPath: rp.configurationGeneralSettingsViewRoot,
      },
      [rp.configurationGeneralSettingsViewPDPConnectionClientInformation]: {
        name: 'Client Information',
        parentPath: rp.configurationGeneralSettingsViewPDPConnectionRoot,
      },
      [rp.configurationGeneralSettingsViewPDPConnectionPublish]: {
        name: 'PDP Publish',
        parentPath: rp.configurationGeneralSettingsViewPDPConnectionRoot,
      },
      [rp.configurationGeneralSettingsViewPDPConnectionPossibleMatchConfiguration]: {
        name: 'Possible Match Configuration',
        parentPath: rp.configurationGeneralSettingsViewPDPConnectionRoot,
      },
      // Configuration / General Settings / View Company / PDP Connection / PDP Registration
      [rp.configurationGeneralSettingsViewPDPConnectionRegistrationList]: {
        name: 'PDP Registration',
        parentPath: rp.configurationGeneralSettingsViewPDPConnectionRoot,
      },
      [rp.configurationGeneralSettingsViewPDPConnectionRegistrationView]: {
        name: bcNamesMap[rp.configurationGeneralSettingsEditPDPConnectionRegistrationView],
        parentPath: rp.configurationGeneralSettingsViewPDPConnectionRegistrationList,
      },

      // Configuration / General Settings / View Company / Additional options
      [rp.configurationGeneralSettingsViewAdditionalOptions]: {
        name: 'Additional options',
        parentPath: rp.configurationGeneralSettingsViewRoot,
      },
      // Configuration / General Settings / View Company / Complex matching
      [`${rp.configurationGeneralSettingsViewComplexMatchingRoot}/*`]: {
        name: 'Complex matching',
        parentPath: rp.configurationGeneralSettingsViewRoot,
      },

      // Configuration / General Settings / Edit Company
      [rp.configurationGeneralSettingsEditRoot]: {
        name: bcNamesMap[rp.configurationGeneralSettingsEditRoot],
        parentPath: rp.configurationGeneralSettingsList,
      },
      // Configuration / General Settings / Edit Company / PDP Connection
      [rp.configurationGeneralSettingsEditPDPConnectionRoot]: {
        name: 'PDP connection',
        parentPath: rp.configurationGeneralSettingsEditRoot,
      },
      [rp.configurationGeneralSettingsEditPDPConnectionClientInformation]: {
        name: 'Client Information',
        parentPath: rp.configurationGeneralSettingsEditPDPConnectionRoot,
      },
      [rp.configurationGeneralSettingsEditPDPConnectionPublish]: {
        name: 'PDP Publish',
        parentPath: rp.configurationGeneralSettingsEditPDPConnectionRoot,
      },
      [rp.configurationGeneralSettingsEditPDPConnectionPossibleMatchConfiguration]: {
        name: 'Possible Match Configuration',
        parentPath: rp.configurationGeneralSettingsEditPDPConnectionRoot,
      },
      // Configuration / General Settings / Edit Company / PDP Connection / PDP Registration
      [rp.configurationGeneralSettingsEditPDPConnectionRegistrationList]: {
        name: 'PDP Registration',
        parentPath: rp.configurationGeneralSettingsEditPDPConnectionRoot,
      },
      [rp.configurationGeneralSettingsEditPDPConnectionRegistrationCreate]: {
        name: 'Register New Schemes',
        parentPath: rp.configurationGeneralSettingsEditPDPConnectionRegistrationList,
      },
      [rp.configurationGeneralSettingsEditPDPConnectionRegistrationView]: {
        name: bcNamesMap[rp.configurationGeneralSettingsEditPDPConnectionRegistrationView],
        parentPath: rp.configurationGeneralSettingsEditPDPConnectionRegistrationList,
      },
      [rp.configurationGeneralSettingsEditPDPConnectionRegistrationEdit]: {
        name: bcNamesMap[rp.configurationGeneralSettingsEditPDPConnectionRegistrationEdit],
        parentPath: rp.configurationGeneralSettingsEditPDPConnectionRegistrationList,
      },

      // Configuration / General Settings / Edit Company / Additional options
      [rp.configurationGeneralSettingsEditAdditionalOptions]: {
        name: 'Additional options',
        parentPath: rp.configurationGeneralSettingsEditRoot,
      },
      // Configuration / General Settings / Edit Company / Complex matching
      [`${rp.configurationGeneralSettingsEditComplexMatchingRoot}/*`]: {
        name: 'Complex matching',
        parentPath: rp.configurationGeneralSettingsEditRoot,
      },
      [rp.configurationGeneralSettingsEditComplexMatchingEditRules]: {
        name: 'Review Rules',
        parentPath: `${rp.configurationGeneralSettingsEditComplexMatchingRoot}/*`,
      },

      // Configuration / Matching
      [rp.configurationMatchingRoot]: { name: 'Matching', parentPath: rp.configurationRoot },

      // Configuration / Matching / Policies / Main
      [rp.configurationMatchingPolicyList]: { name: 'All Policies', parentPath: rp.configurationMatchingRoot },
      [rp.configurationMatchingPolicyReview]: {
        name: bcNamesMap[rp.configurationMatchingPolicyReview],
        parentPath: rp.configurationMatchingPolicyList,
      },

      // Configuration / Matching / Policies / Create Alternative Policy
      [`${rp.configurationMatchingPolicyCreateRoot}/*`]: {
        name: 'Create New Policy',
        parentPath: rp.configurationMatchingRoot,
      },

      // Configuration / Matching / Policies / Confirm Default Policy
      [`${rp.configurationMatchingDefaultPolicyCreateRoot}/*`]: {
        name: 'Confirm Default Policy',
        parentPath: rp.configurationMatchingRoot,
      },

      // Configuration / Matching / Policies / Main / View Policy
      [rp.configurationMatchingPolicyViewRoot]: {
        name: bcNamesMap[rp.configurationMatchingPolicyViewRoot],
        parentPath: rp.configurationMatchingPolicyList,
      },
      [rp.configurationMatchingPolicyViewPolicyDetails]: {
        name: 'Policy Details',
        parentPath: rp.configurationMatchingPolicyViewRoot,
      },
      [rp.configurationMatchingPolicyViewPolicyMatchRules]: {
        name: 'Rule Engine',
        parentPath: rp.configurationMatchingPolicyViewRoot,
      },
      [rp.configurationMatchingPolicyViewPolicySchemes]: {
        name: 'Schemes',
        parentPath: rp.configurationMatchingPolicyViewRoot,
      },
      [rp.configurationMatchingPolicyViewPolicySchemes]: {
        name: 'Schemes',
        parentPath: rp.configurationMatchingPolicyViewRoot,
      },
      [rp.configurationMatchingPolicyViewPolicyVersionList]: {
        name: 'Version History',
        parentPath: rp.configurationMatchingPolicyViewRoot,
      },
      [rp.configurationMatchingPolicyViewPolicyVersionDetails]: {
        name: bcNamesMap[rp.configurationMatchingPolicyViewPolicyVersionDetails],
        parentPath: rp.configurationMatchingPolicyViewPolicyVersionList,
      },

      // Configuration / Matching / Policies / Main / Edit Policy
      [rp.configurationMatchingPolicyEditRoot]: {
        name: bcNamesMap[rp.configurationMatchingPolicyEditRoot],
        parentPath: rp.configurationMatchingPolicyList,
      },
      [rp.configurationMatchingPolicyEditPolicyDetails]: {
        name: 'Policy Details',
        parentPath: rp.configurationMatchingPolicyEditRoot,
      },
      [rp.configurationMatchingPolicyEditPolicyMatchRules]: {
        name: 'Rule Engine',
        parentPath: rp.configurationMatchingPolicyEditRoot,
      },
      [rp.configurationMatchingPolicyEditPolicyMatchRulesRules]: {
        name: 'Create Rule',
        parentPath: rp.configurationMatchingPolicyEditPolicyMatchRules,
      },
      [rp.configurationMatchingPolicyEditPolicyMatchRulesRulesEditRule]: {
        name: 'Edit Rule',
        parentPath: rp.configurationMatchingPolicyEditPolicyMatchRules,
      },
      [rp.configurationMatchingPolicyEditPolicySchemes]: {
        name: 'Schemes',
        parentPath: rp.configurationMatchingPolicyEditRoot,
      },

      // Configuration / Matching / Matching Test
      [rp.configurationMatchingTestMain]: { name: 'Matching Test', parentPath: rp.configurationMatchingRoot },
      [rp.configurationMatchingTestViewMemberRoot]: {
        name: bcNamesMap[rp.configurationMatchingTestViewMemberRoot],
        parentPath: rp.configurationMatchingTestMain,
      },
      [rp.configurationMatchingTestViewMemberDetails]: {
        name: 'Details',
        parentPath: rp.configurationMatchingTestViewMemberRoot,
      },
      [rp.configurationMatchingTestViewMemberAdministrative]: {
        name: 'Pension Administrative Details',
        parentPath: rp.configurationMatchingTestViewMemberRoot,
      },
      [rp.configurationMatchingTestViewMemberPensionValue]: {
        name: 'Pension Value Details',
        parentPath: rp.configurationMatchingTestViewMemberRoot,
      },
      [rp.configurationMatchingTestViewMemberMatchingDetails]: {
        name: 'Matching Details',
        parentPath: rp.configurationMatchingTestViewMemberRoot,
      },

      // Configuration / Publish Configuration
      [rp.configurationPublishConfigurationRoot]: { name: 'Publish Configuration', parentPath: rp.configurationRoot },
      [rp.configurationPublishConfigurationPublishChanges]: {
        name: 'Publish Changes',
        parentPath: rp.configurationPublishConfigurationRoot,
      },
      [rp.configurationPublishConfigurationAuditList]: {
        name: 'Audit',
        parentPath: rp.configurationPublishConfigurationRoot,
      },
      [rp.configurationPublishConfigurationAuditDetails]: {
        name: 'View Audit',
        parentPath: rp.configurationPublishConfigurationAuditList,
      },

      // DATA MANAGEMENT
      [rp.dataManagementRoot]: { name: 'Data Management', parentPath: rp.root },

      // Data Management / Data Upload History
      [rp.dataManagementHistoryList]: { name: 'Data Upload History', parentPath: rp.dataManagementRoot },
      [rp.dataManagementHistoryFileViewRoot]: {
        name: bcNamesMap[rp.dataManagementHistoryFileViewRoot],
        parentPath: rp.dataManagementHistoryList,
      },
      [rp.dataManagementHistoryFileViewUploadSummary]: {
        name: 'Upload Summary',
        parentPath: rp.dataManagementHistoryFileViewRoot,
      },
      [rp.dataManagementHistoryFileViewUploadAudit]: {
        name: 'Upload Audit',
        parentPath: rp.dataManagementHistoryFileViewRoot,
      },
      [rp.dataManagementHistoryFileViewPDPSynchronisation]: {
        name: 'PDP Synchronisation details',
        parentPath: rp.dataManagementHistoryFileViewRoot,
      },

      // Data Management / Upload Source Data
      [rp.dataManagementUploadSourceData]: { name: 'Upload Source Data', parentPath: rp.dataManagementRoot },

      // Data Management / Member Search
      [rp.dataManagementMemberList]: { name: 'Member Search', parentPath: rp.dataManagementRoot },

      // Data Management / Member Search / View Details
      [rp.dataManagementMemberViewRoot]: {
        name: bcNamesMap[rp.dataManagementMemberViewRoot],
        parentPath: rp.dataManagementMemberList,
      },

      // Data Management / Member Search / View Details / Membership Detail
      [rp.dataManagementMemberViewDetailRoot]: {
        name: 'Membership Detail',
        parentPath: rp.dataManagementMemberViewRoot,
      },
      [rp.dataManagementMemberViewDetailPersonal]: {
        name: 'Personal Details',
        parentPath: rp.dataManagementMemberViewDetailRoot,
      },
      [rp.dataManagementMemberViewDetailPensionAccount]: {
        name: 'Pension Account Details',
        parentPath: rp.dataManagementMemberViewDetailRoot,
      },
      [rp.dataManagementMemberViewDetailEmployerHistory]: {
        name: 'Employment History',
        parentPath: rp.dataManagementMemberViewDetailRoot,
      },
      [rp.dataManagementMemberViewDetailStatus]: {
        name: 'PDP Status',
        parentPath: rp.dataManagementMemberViewDetailRoot,
      },

      // Data Management / Member Search / View Details / Pension Value Details
      [rp.dataManagementMemberViewPensionValueRoot]: {
        name: 'Pension Value Detail',
        parentPath: rp.dataManagementMemberViewRoot,
      },
      // Data Management / Member Search / View Details / Pension Value Details / ERI
      [`${rp.dataManagementMemberViewPensionValueERIRoot}/*`]: {
        name: 'Estimated Retirement Income ( ERI )',
        parentPath: rp.dataManagementMemberViewPensionValueRoot,
      },
      [rp.dataManagementMemberViewPensionValueERICreate]: {
        name: 'Create ERI',
        parentPath: rp.dataManagementMemberViewPensionValueRoot,
      },
      // Data Management / Member Search / View Details / Pension Value Details / Accrued
      [`${rp.dataManagementMemberViewPensionValueAccruedRoot}/*`]: {
        name: 'Accrued Pension Benefit',
        parentPath: rp.dataManagementMemberViewPensionValueRoot,
      },
      [rp.dataManagementMemberViewPensionValueAccruedCreate]: {
        name: 'Create Accrued Pension',
        parentPath: rp.dataManagementMemberViewPensionValueRoot,
      },

      // Data Management / Member Search / View Details / Matching Details
      [`${rp.dataManagementMemberViewMatchingDetailsRoot}/*`]: {
        name: 'Matching Details',
        parentPath: rp.dataManagementMemberViewRoot,
      },

      // Data Management / Member Search / View Details / Audit
      [rp.dataManagementMemberViewAuditList]: {
        name: 'Audit',
        parentPath: rp.dataManagementMemberViewRoot,
      },
      [rp.dataManagementMemberViewAuditDetails]: {
        name: bcNamesMap[rp.dataManagementMemberEditAuditDetails],
        parentPath: rp.dataManagementMemberViewAuditList,
      },

      // Data Management / Member Search / Edit Details
      [rp.dataManagementMemberEditRoot]: {
        name: bcNamesMap[rp.dataManagementMemberEditRoot],
        parentPath: rp.dataManagementMemberList,
      },

      // Data Management / Member Search / Edit Details / Membership Detail
      [rp.dataManagementMemberEditDetailRoot]: {
        name: 'Membership Detail',
        parentPath: rp.dataManagementMemberEditRoot,
      },
      [rp.dataManagementMemberEditDetailPersonal]: {
        name: 'Personal Details',
        parentPath: rp.dataManagementMemberEditDetailRoot,
      },
      [rp.dataManagementMemberEditDetailPensionAccount]: {
        name: 'Pension Account Details',
        parentPath: rp.dataManagementMemberEditDetailRoot,
      },
      [rp.dataManagementMemberEditDetailEmployerHistory]: {
        name: 'Employment History',
        parentPath: rp.dataManagementMemberEditDetailRoot,
      },
      [rp.dataManagementMemberEditDetailStatus]: {
        name: 'PDP Status',
        parentPath: rp.dataManagementMemberEditDetailRoot,
      },

      // Data Management / Member Search / Edit Details / Pension Value Details
      [rp.dataManagementMemberEditPensionValueRoot]: {
        name: 'Pension Value Detail',
        parentPath: rp.dataManagementMemberEditRoot,
      },
      // Data Management / Member Search / Edit Details / Pension Value Details / ERI
      [`${rp.dataManagementMemberEditPensionValueERIRoot}/*`]: {
        name: 'Estimated Retirement Income ( ERI )',
        parentPath: rp.dataManagementMemberEditPensionValueRoot,
      },
      [rp.dataManagementMemberEditPensionValueERICreate]: {
        name: 'Create ERI',
        parentPath: rp.dataManagementMemberEditPensionValueRoot,
      },
      // Data Management / Member Search / Edit Details / Pension Value Details / Accrued
      [`${rp.dataManagementMemberEditPensionValueAccruedRoot}/*`]: {
        name: 'Accrued Pension Benefit',
        parentPath: rp.dataManagementMemberEditPensionValueRoot,
      },
      [rp.dataManagementMemberEditPensionValueAccruedCreate]: {
        name: 'Create Accrued Pension',
        parentPath: rp.dataManagementMemberEditPensionValueRoot,
      },

      // Data Management / Member Search / Edit Details / Matching Details
      [`${rp.dataManagementMemberEditMatchingDetailsRoot}/*`]: {
        name: 'Matching Details',
        parentPath: rp.dataManagementMemberEditRoot,
      },

      // Data Management / Member Search / Edit Details / Audit
      [rp.dataManagementMemberEditAuditList]: {
        name: 'Audit',
        parentPath: rp.dataManagementMemberEditRoot,
      },
      [rp.dataManagementMemberEditAuditDetails]: {
        name: bcNamesMap[rp.dataManagementMemberEditAuditDetails],
        parentPath: rp.dataManagementMemberEditAuditList,
      },

      // Data Management / Audit
      [rp.dataManagementAuditList]: {
        name: 'Audit',
        parentPath: rp.dataManagementRoot,
      },
      [rp.dataManagementAuditDetails]: {
        name: bcNamesMap[rp.dataManagementAuditDetails],
        parentPath: rp.dataManagementAuditList,
      },

      // ANALYTICS & REPORTS
      [rp.analyticsRoot]: { name: 'Analytics & Reports', parentPath: rp.root },
      [rp.analyticsPensionFusionInsight]: { name: 'Pension Fusion Insights', parentPath: rp.analyticsRoot },
      [rp.analyticsPossibleMatches]: { name: 'Possible Matches', parentPath: rp.analyticsRoot },
      [rp.analyticsMissingViewValues]: { name: 'Missing View Values', parentPath: rp.analyticsRoot },
      [rp.analyticsMemberExtract]: { name: 'Member Extract', parentPath: rp.analyticsRoot },
      [rp.analyticsFileExtractAudit]: { name: 'Audit', parentPath: rp.analyticsRoot },

      // PRODUCT INFO
      [rp.productInfo]: { name: 'Product Information', parentPath: rp.root },
    }),
    [bcNamesMap],
  );

  // Debounce breadcrumbs update to avoid unnecessary updates by redirects
  useEffect(() => {
    if (isHidden) return;

    const delayTimeout = setTimeout(() => {
      setBreadcrumbs(getBreadcrumbs(pathname, config));
    }, 50);
    return () => {
      clearTimeout(delayTimeout);
    };
  }, [config, isHidden, pathname]);

  return isHidden ? null : <Breadcrumbs breadcrumbs={breadcrumbs} />;
}

export default BreadcrumbsTrail;
