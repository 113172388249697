import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from 'react-router-dom';
import { resetStore } from 'src/store';

type RouterState = {
  previousLocation: Location;
};

const initialState: RouterState = {
  previousLocation: { pathname: '/', hash: '', search: '', state: null, key: '' },
};

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setPreviousLocation(state: RouterState, action: PayloadAction<Location>) {
      state.previousLocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, (state: RouterState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setPreviousLocation } = routerSlice.actions;

export default routerSlice.reducer;
