import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Role, GlobalRole } from 'src/types';

import { Converter, jwtRoleKey, jwtUserNameKey, jwtUserId } from '@itm/shared-frontend/lib/utils';

export const authSliceSelector = (state: RootState) => state.auth;

export const authUserDataSelector = createSelector(authSliceSelector, (state) => state.identityUser);

export const authUserAttributesSelector = createSelector(
  authUserDataSelector,
  (userData) =>
    userData?.userAttributes?.reduce<Record<string, string | null>>(
      (acc, { name, value }) => (name ? { ...acc, [name]: value } : acc),
      {},
    ) || {},
);

export const authRoleSelector = createSelector(authUserAttributesSelector, (userAttributes): Role | null => {
  const rolesAttribute: string | null = userAttributes[jwtRoleKey] || null;
  if (!rolesAttribute) return null;
  const roles = rolesAttribute.split(/;\s?/).map((roleWithExtra: string) => {
    const index = roleWithExtra.indexOf(':');
    const role = index === -1 ? roleWithExtra : roleWithExtra.slice(0, index);
    return role.toLocaleLowerCase();
  });

  return Object.values(Role).find((role) => roles.includes(role.toLocaleLowerCase())) || null;
});

export const authRoleWithoutModuleNameSelector = createSelector(authRoleSelector, (role): string | null => {
  if (!role) return null;
  return role === Role.SuperAdmin || role === Role.Support ? role : role.slice(role.indexOf('_') + 1);
});

export const authRoleReadableNameSelector = createSelector(
  authRoleWithoutModuleNameSelector,
  (authRoleWithoutModuleName): string | null => {
    if (!authRoleWithoutModuleName) return null;
    return Converter.stripUpperToCapitalizedAll(authRoleWithoutModuleName);
  },
);

export const authUserNameSelector = createSelector(
  authUserAttributesSelector,
  (userAttributes): string | null => userAttributes[jwtUserNameKey] || null,
);

export const authUserIdSelector = createSelector(
  authUserAttributesSelector,
  (userAttributes): string | null => userAttributes[jwtUserId] || null,
);

export const authRoleListSelector = createSelector(authUserAttributesSelector, (userAttributes): GlobalRole[] => {
  const rolesAttribute = userAttributes[jwtRoleKey];
  if (!rolesAttribute) return [];
  return rolesAttribute.split(/;\s?/).map((roleWithExtra: string) => {
    const index = roleWithExtra.indexOf(':');
    const globalRole = index === -1 ? roleWithExtra : roleWithExtra.slice(0, index);
    return globalRole as GlobalRole;
  });
});

export const isLoggedInSelector = createSelector(authRoleSelector, (role) => Boolean(role));
